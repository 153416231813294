
import { defineComponent, reactive, onMounted, computed } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";

interface Filter {
  name: string;
  email: string;
}

export default defineComponent({
  name: "filter-client",
  components: {},
  setup(props, context) {
    const { query } = useQuery();

    const queryParam = reactive({
      name: '',
      email: ''
    });

    const fetchParams = computed(() => ({
      name: queryParam.name,
      email: queryParam.email,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const data = reactive<Filter>({
      name: query.value.name || '',
      email: query.value.email || '',
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.name = '';
      data.email = '';
    };

    return {
      data,
      filter,
      reset,
    };
  }
});
