
import { reactive, defineComponent, computed, ref, onMounted, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import useClientList from "@/core/services/compositions/client/useClientList";
import { useI18n } from "vue-i18n";
import {
  showErrorPopup,
  showSuccessPopup,
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import { ElNotification } from 'element-plus'
import TableSkeleton from "@/components/skeleton/Table.vue";
import Pagination from "@/components/Pagination.vue";
import moment from 'moment';
import { checkPermission } from '@/router/authentication';
import { MenuComponent } from "@/assets/ts/components";
import FilterClient from "./filter-client.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterClient
  },
  setup() {
    // init
    const { t } = useI18n();
    const router = useRouter();
    const { query } = useQuery();
    const dialogDeleteVisible = ref(false);
    const clientHash = ref();
    const isProcess = ref<boolean>(false);

    // breadcrumb
    setNewPageBreadcrumbs(t("management.client.title"), [
      { title: t("management.client.title") }
    ]);

    const gotoCreate = () => {
      router.push({ name: "app.management.client.create" });
    };

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      name: query.value.name || '',
      email: query.value.email || '',
      order_by: 'created_at',
      order: 'DESC',
    }));

    const { data, isValidating, mutate: refreshList, error } = useClientList(() => fetchParams.value);

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    const form = reactive({
      name: '',
      email: '',
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });

    const filtered = (filters) => {
      Object.assign(form, filters);
      mutateQuery({
        page: 1,
        name: form.name,
        email: form.email,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const showDeleteDialog = (hash) => {
      clientHash.value = hash;

      dialogDeleteVisible.value = true;
    };

    const deleteClient = () => {
      isProcess.value = true;
      dialogDeleteVisible.value = false;

      ApiService.setHeader();
      ApiService.delete(
        `app/entity/client/${clientHash.value}`)
        .then((data) => {
          showSuccessPopup({
            title: 'Status',
            text: 'Deleted'
          });

          refreshList();
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          isProcess.value = false;
        });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      data,
      isValidating,
      gotoCreate,
      handleChangePage,
      moment,
      checkPermission,
      FilterClient,
      filtered,
      deleteClient,
      dialogDeleteVisible,
      showDeleteDialog,
      isProcess
    };
  },
  methods: {}
});
